import { Link } from "gatsby";
import React, { FC } from "react";
import { Breadcrumb, BreadcrumbItem, Col } from "react-bootstrap";
import "./styles.scss";

const BreadcrumbLinks: FC<{ items: any; className?: string }> = ({
  items,
  className,
}) => {
  return (
    <div
      className={`d-flex ${className ? className : ""}`}
      style={{ flexWrap: "wrap" }}
    >
      <Col lg={12} className="p-0">
        <Breadcrumb>
          {items.map(
            (item: {
              to: string;
              active: boolean;
              name: string;
              externalLink?: string;
            }) => (
              <BreadcrumbItem key={item.to} active={item.active}>
                {item.externalLink ? (
                  <>
                    <span>{item.name}</span>{" "}
                    <a href={item.to} target="_blank">
                      {item.to}
                    </a>
                  </>
                ) : item.active ? (
                  item.name
                ) : (
                  <Link to={item.to}>{item.name}</Link>
                )}
              </BreadcrumbItem>
            )
          )}
        </Breadcrumb>
      </Col>
    </div>
  );
};

export default BreadcrumbLinks;
