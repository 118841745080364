import React, {FC, useEffect, useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import {Link} from 'gatsby';
import AutomationCards from '../automation-cards';
import {ITransformAutomationProps} from '../../../types';
import './styles.scss';
import {cleanURL} from '../../../helpers';
import NothingToShow from '../../nothingToShow';

const AutomationCardsContainer: FC<ITransformAutomationProps> = ({data}) => {
  let initailList = data?.list || [];

  const [list, setList] = useState(initailList);

  useEffect(() => {
    const chatGPT = data?.list.find((item: any) => item.id === 143);
    const chatGPT16 = data?.list.find((item: any) => item.id === 145);
    const chatGPT4 = data?.list.find((item: any) => item.id === 146);
    const googleBard = data?.list.find((item: any) => item.id === 147);
    const googleGemini = data?.list.find((item: any) => item.id === 149);
    const chatGPTVision = data?.list.find((item: any) => item.id === 150);
    const youtube = data?.list.find((item: any) => item.id === 152);

    if (chatGPT || chatGPT16 || chatGPT4 || googleBard || googleGemini || chatGPTVision) {
      const arr = [
        youtube || null,
        chatGPTVision || null,
        googleGemini || null,
        googleBard || null,
        chatGPT4 || null,
        chatGPT16 || null,
        chatGPT || null,
        ...data?.list.filter((item: any) => item.id !== 143 && item.id !== 145 && item.id !== 146 && item.id !== 147 && item.id !== 149 && item.id !== 150 && item.id !== 152),
      ];
      const filteredArr = arr.filter((item: any) => item);
      //@ts-ignore
      filteredArr && setList(filteredArr);
    } else {
      setList(data?.list);
    }
  }, [data]);

  if (!list.length) return <NothingToShow />;
  return (
    <Row className="automation-card-container">
      <Col sm={12} className="automation-container-header">
        <div className="automation-card-title">
          <div>{!!list.length && list[0] && list[0].category && list[0].category.name}</div>
        </div>
        {/*<div className='show-more'><Link to={`/automations/${title}`}>Show more</Link></div>*/}
      </Col>
      {list.map(
        item =>
          item && (
            <Col md={6} lg={4} className="p-2">
              <Link to={`/automation/${cleanURL(item.url)}`}>
                <AutomationCards
                  title={item.name}
                  description={item.long_description}
                  icon={item.icon_url}
                  rate={item.rate || 5}
                  active={item.active || false}
                  credit_price={item.credit_price}
                  premium_credit_price={item.premium_credit_price}
                  type={item.type}
                  is_new={item.is_new}
                />
              </Link>
            </Col>
          ),
      )}
    </Row>
  );
};

export default AutomationCardsContainer;
