import React, {useState, useEffect, useContext} from 'react';
import UserWrapper from '../userWrap';
import qs from 'query-string';
import Header from '../header/index';
//import CookiePolicy from "../cookie-policy";
import Login from '../../components/login/index';
import Footer from '../footer';
import {
  getUserExists,
  setImpactClickIdCookie,
  setImpactMediaPartnerIdCookie,
  removeImpactMediaPartnerIdCookie,
  removeImpactClickIdCookie,
  getRawCookie,
  removeDocumentReferrer,
  setDocumentReferrer,
  logOut,
  getUserEmail,
  getShowModalonExit,
  setShowModalonExit,
  getNewUser,
  // removeNewUser,
  // getLTDHeader,
  sendUserDataToExtension,
  setNewUser,
  getLTDPromo,
  getShowDemoModalonExit,
  setShowDemoModalonExit,
} from '../../helpers';
import CustomModal from '../customModal/index';
import ForgotPassword from '../../components/forgot-password/index';
import Registration from '../../components/registration/index';
import SubscriptionModal from '../../components/subscriptionModal';

import {faSignOutAlt, IconDefinition, faChartLine} from '@fortawesome/free-solid-svg-icons';
import {MenuItem} from '../../types';
// import {GET_HEXOMATIC_USER, ME} from '../../graphql/queries';
// import {useQuery} from '@apollo/react-hooks';
// import {UserContext} from '../../helpers/contexts/user-context';
import {navigate} from 'gatsby';
import {useMutation, useQuery} from '@apollo/react-hooks';
import {LOG_OUT} from '../../graphql/mutations';
import RequestDemo from '../demo-request';
import {GET_HEXOMATIC_USER, ME} from '../../graphql/queries';
import {openCalendly} from '../../helpers/trackingEvents';
import CookieConsentPopup from '../cookie-popup';
import StickyMessage from '../sticky-message';

const Layout = ({children, pricingPromo, pricingPage, pricingPageLtd, fusebasePromo, shorthLtd, noBanner}: any) => {
  const [show, setShow] = useState(false);
  const {data: userData} = useQuery(ME);
  const {data: hexomaticUser, loading} = useQuery(GET_HEXOMATIC_USER, {
    fetchPolicy: 'no-cache',
  });
  // const [generateApolloData] = useLazyQuery(GENERATE_APOLLO_DATA);
  const [showLogin, setShowLogin] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [showRegistration, setShowRegistration] = useState(false);
  const [showDemo, setShowDemo] = useState(false);
  const [demoSuccess, setDemoSuccess] = useState(false);
  const [timeoutId, setTimeoutId] = useState<any>('');
  const [handleCloseDemoModal, setHandleCloseDemoModal] = useState<boolean>(false);

  const [promoCode, setPromoCode] = useState('');
  const [showSubItems, setShowSubItems] = useState(false);
  const [email, setEmail] = useState('email');
  const [promoOpen, setPromoOpen] = useState(false);
  // const [holidaysSale, setHolidaysSale] = useState(false);
  const [currentPackage, setCurrentPackage] = useState('');
  const [currentPackageInterval, setCurrentPackageInterval] = useState('');
  const [handleCloseLoginModal, setHandleCloseLoginModal] = useState<boolean>(false);
  const [handleCloseRegModal, setHandleCloseRegModal] = useState<boolean>(false);

  // const [paypalToken] = useMutation(PAYPAL_UPGRADE);
  // const [paypalApproved] = useMutation(PAYPAL_PAYMENT_APPROVED);
  const [log_out] = useMutation(LOG_OUT);

  useEffect(() => {
    if (hexomaticUser && hexomaticUser.HexomaticUser && hexomaticUser.HexomaticUser.getByLegacyId) {
      setCurrentPackage(hexomaticUser.HexomaticUser.getByLegacyId.pricing_package);
      setCurrentPackageInterval(hexomaticUser.HexomaticUser.getByLegacyId.pricing_package_interval);
    } else {
      setCurrentPackage('');
      setCurrentPackageInterval('');
    }
  }, [hexomaticUser]);

  useEffect(() => {
    // const userNotFound =
    //   hexomaticUser &&
    //   hexomaticUser.HexomaticUser &&
    //   hexomaticUser.HexomaticUser.getByLegacyId &&
    //   hexomaticUser.HexomaticUser.getByLegacyId.error_code;

    const validUser = userData?.User?.get?.promoData?.hexomatic_ltd;

    // const path = global && global.window && global.window.location && global.window.location.pathname;
    // const el = document && document.getElementById('seo_home_area_tools');

    // const visitedPromoPage = (!loading && userNotFound && getLTDPromo()) || (!userNotFound && validUser);
    // const toolsPageCheck = false;
    // path &&
    //   path.includes('/tools/') &&
    //   ((hexomaticUser && !hexomaticUser.id) ||
    //     (hexomaticUser &&
    //       (hexomaticUser.pricing_package === 'FREE' || hexomaticUser.pricing_package_interval === 'LTD')))
    if (validUser) {
      setPromoOpen(true);
      // el && (el.className = 'seo_home_area special-section promo-padding-top')
    } else {
      setPromoOpen(false);
      // el && (el.className = 'seo_home_area special-section');
    }
  }, [loading, currentPackage, hexomaticUser, userData]);

  const logOutHandler = async (id: number): Promise<void> => {
    const {data} = await log_out({variables: {id, device_key: getRawCookie('device_key')}});
    if (data && data.UserOps && data.UserOps.logout && !data.UserOps.logout.error) {
      logOut();
      setEmail(getUserEmail() || '');
      sendUserDataToExtension();
    }
  };

  // const logOutHandler = async (id: number): Promise<void> => {
  //   logOut();
  //   setEmail(getUserEmail() || '');
  //   sendUserDataToExtension();
  // };

  const HeaderMenuItems = (isLoggedIn: boolean, firstName?: string, id?: number, src?: string) => {
    const headerMenuItems: {
      name?: string;
      linkTo?: string | null;
      className?: string;
      icon?: IconDefinition | string;
      img?: boolean;
      src?: string;
      target?: string;
      subItems?: Array<MenuItem>;
      showSubItems?: boolean;
      subMenu?: any;
      func?: () => void | Promise<void> | any;
    }[] = [
      {name: 'Why Hexomatic', linkTo: '/about/', className: 'nav-link'},
      {name: 'Automations', linkTo: '/automations/', className: 'nav-link'},
      {name: 'Workflow templates', linkTo: '/ready-made-workflow-library/', className: 'nav-link'},
      // {name: 'Tools', linkTo: '/tools', className: 'nav-link'},
      {name: 'Pricing', linkTo: '/pricing/', className: 'nav-link'},
      // {
      //   name: 'FAQ',
      //   linkTo: '/faq/',
      //   className: 'nav-link',
      // },
      {
        name: 'Academy',
        linkTo: 'https://hexomatic.com/academy/',
        className: 'nav-link',
        subMenu: [
          {
            name: 'Insider Guides',
            linkTo: 'https://hexomatic.com/academy/category/insider-guides/',
            className: 'nav-link',
          },
          {
            name: 'News',
            linkTo: 'https://newsletter.hexact.io/',
            className: 'nav-link',
          },
          {
            name: 'Tutorials',
            linkTo: 'https://hexomatic.com/academy/tutorials',
            className: 'nav-link',
          },
        ],
      },
      // {name: 'Roadmap', linkTo: '/roadmap', className: 'nav-link'},
    ];
    if (!isLoggedIn) {
      headerMenuItems.push(
        {
          name: 'Login',
          linkTo: `/login`,
          className: 'nav-link header_login_btn d-lg-none',
        },
        {
          name: 'Get started',
          linkTo: '/registration',
          className: 'nav-link software_banner_btn header_registration_btn d-lg-none',
          icon: undefined,
        },
        {
          name: 'Request a demo',
          // linkTo: '/#demo',
          className: 'nav-link software_banner_btn outline header_registration_btn d-lg-none',
          icon: undefined,
          func: () => openCalendly(),
        },
      );
    } else {
      headerMenuItems.push({
        name: firstName,
        className: 'nav-link loggedin-items d-lg-none',
        img: true,
        src: src,
        func: () => setShowSubItems(!showSubItems),
        showSubItems: showSubItems,
        subItems: [
          {
            name: 'Dashboard',
            className: 'nav-link loggedin-items d-lg-none',
            linkTo: window.location.host.includes('localhost')
              ? 'http://localhost:3000'
              : window.location.host.includes('37.186.119.181')
              ? 'http://37.186.119.181:3279'
              : 'https://dash.hexomatic.com',
            icon: faChartLine,
          },
          {
            name: 'Sign out',
            className: 'nav-link loggedin-items d-lg-none',
            icon: faSignOutAlt,
            func: () => id && logOutHandler(id),
          },
        ],
      });
    }
    return headerMenuItems;
  };

  const handleDetectPosition = (e: any) => {
    const hexomaticUserLocal = hexomaticUser?.HexomaticUser?.getByLegacyId;
    const freeOrNoUser = hexomaticUserLocal && (hexomaticUserLocal.pricing_package === 'FREE' || !hexomaticUserLocal.pricing_package);
    if (
      e.clientY < 20 &&
      getShowDemoModalonExit() !== 'false' &&
      freeOrNoUser &&
      !document.querySelector('.go-to-login-page') &&
      !document.querySelector('.show-tooltip') &&
      (global?.window?.location?.href?.includes('pricing') || global?.window?.location?.href?.includes('promo')) &&
      getRawCookie('cookie_preference')
    ) {
      setShowDemoModalonExit('false');
      openCalendly();
    } else if (
      e.clientY < 20 &&
      getShowModalonExit() !== 'false' &&
      getUserExists() !== 'true' &&
      !document.querySelector('.go-to-login-page') &&
      !document.querySelector('.show-tooltip') &&
      !(global?.window?.location?.href?.includes('pricing') || global?.window?.location?.href?.includes('promo')) &&
      getRawCookie('cookie_preference')
    ) {
      setShowModalonExit('true');
      setShow(true);
    }
  };

  useEffect(() => {
    if (window.location.href.includes('PayerID=') || window.location.href.includes('&ba_token=')) {
      window.close();
    }
  }, []);

  useEffect(() => {
    //@ts-ignore
    if (global && global.window && global.window.location) {
      //@ts-ignore
      if (
        global &&
        global.window &&
        global.window.location &&
        global.window.location.search &&
        global.window.location.search.length > 0
      ) {
        //@ts-ignore
        const queryData = qs.parse(global.window.location.search);
        if (queryData && queryData['promo-code'] && queryData['promo-code'].length > 0) {
          setPromoCode(queryData['promo-code'] as string);
        }
      }
    }
    //@ts-ignore
  }, [global && global.window && global.window.location]);

  useEffect(() => {
    global.document && global.document.addEventListener('mousemove', handleDetectPosition);
    return () => {
      global.document.removeEventListener('mousemove', handleDetectPosition);
    };
  }, [hexomaticUser]);

  useEffect(() => {
    // Setting document referrer
    const referrer =
      typeof document !== 'undefined' &&
      document &&
      document.referrer &&
      document.referrer.indexOf('https://hexomatic.com') === -1 &&
      document.referrer;
    if (referrer && getRawCookie('document_referrer')) {
      removeDocumentReferrer();
    }
    referrer && setDocumentReferrer(referrer);

    // Setting Impact irclickid in cookies
    const url = window.location.href;
    if (url && url.indexOf('irclickid=') > -1) {
      if (getRawCookie('__irClickId')) {
        removeImpactClickIdCookie();
      }
      const id = url.slice(url.indexOf('irclickid=') + 10);
      const idFin = id.slice(0, id.indexOf('&'));
      setImpactClickIdCookie(idFin);
      removeImpactMediaPartnerIdCookie();
    } else if (url && url.toLocaleLowerCase().indexOf('mediapartnerid=') > -1) {
      if (getRawCookie('__mediaPartnerId')) {
        removeImpactMediaPartnerIdCookie();
      }
      const id = url.slice(url.toLocaleLowerCase().indexOf('mediapartnerid=') + 15);
      const idFin = id.slice(0, id.indexOf('&'));
      setImpactMediaPartnerIdCookie(idFin);
      removeImpactClickIdCookie();
    }
  }, []);

  useEffect(() => {
    // Setting document referrer
    const referrer =
      typeof document !== 'undefined' &&
      document &&
      document.referrer &&
      document.referrer.indexOf('https://hexomatic.com') === -1 &&
      document.referrer;
    if (referrer && getRawCookie('document_referrer')) {
      removeDocumentReferrer();
    }
    referrer && setDocumentReferrer(referrer);
  }, []);

  useEffect(() => {
    //@ts-ignore
    if (
      global &&
      global.window &&
      global.window.location &&
      global.window.location.hash &&
      global.window.location.hash === '#login' &&
      global.window.location.pathname === '/'
    ) {
      navigate('/login');
    }
    // @ts-ignore
    if (
      global &&
      global.window &&
      global.window.location &&
      global.window.location.hash &&
      global.window.location.hash === '#registration' &&
      global.window.location.pathname === '/'
    ) {
      const urlParams = new URLSearchParams(global.window.location.search);
      let referral = urlParams.get('referralCode');
      referral ? navigate(`/registration/?referralCode=${referral}`) : navigate('/registration');
    }
    if (
      global &&
      global.window &&
      global.window.location &&
      global.window.location.hash &&
      global.window.location.hash === '#demo'
    ) {
      setShowDemo(true);
    }
    if (
      global &&
      global.window &&
      global.window.location &&
      !global.window.location.pathname.includes('pricing') &&
      !global.window.location.pathname.includes('registration') &&
      !global.window.location.pathname.includes('login') &&
      !global.window.location.pathname.includes('verify-email')
    ) {
      localStorage.removeItem('checkout-data');
    }
    // @ts-ignore
  }, [global && global.window && global.window.location && global.window.location.hash && global.window.location.hash]);

  useEffect(() => {
    if (getNewUser()) {
      if (window.location.href.includes('hexomatic.com')) {
        //@ts-ignore
        window.dataLayer = [];
        //@ts-ignore
        window.dataLayer.push({
          event: `accountRegistration-hexomatic`,
          registrationOrderId: new Date().toISOString(),
        });
        //@ts-ignore
        window.dataLayer.push({
          event: `registration-hexomatic`,
          ecommerce: {
            registration: {
              actionField: {
                id: `Registration_${new Date().toISOString()}`,
              },
            },
          },
        });
      }
      setNewUser(false);
      // generateApolloData();
    }
  }, []);

  const closeModal = () => {
    if (handleCloseLoginModal || handleCloseRegModal) {
      if (localStorage.getItem('checkout-data')) {
        navigate('/pricing/');
      } else {
        if (window.location.host.includes('localhost')) {
          window.location.href = 'http://localhost:3000';
        } else if (window.location.host.includes('37.186.119.181')) {
          window.location.href = 'http://37.186.119.181:3279';
        } else {
          window.location.href = 'https://dash.hexomatic.com';
        }
      }
      return;
    }
    setShowLogin(false);
    setShowRegistration(false);
  };

  const closeDemoModal = () => {
    timeoutId && clearInterval(timeoutId);
    setShowDemo(false);
    setDemoSuccess(false);
    navigate('/');
  };
  return (
    <>
      <UserWrapper
        withoutUser={() => (
          <>
            <Header
              // promoOpen={promoOpen || pricingPromo}
              // holidaysSale={holidaysSale}
              // setPromoOpen={setPromoOpen}
              menuItems={HeaderMenuItems(false)}
              pricingPage={pricingPage}
              pricingPageLtd={pricingPageLtd}
              fusebasePromo={fusebasePromo}
              shorthLtd={shorthLtd}
              noBanner={noBanner}
            />
            <SubscriptionModal
              isModalOpen={
                (global && global.window && global.window.location && global.window.location.hash === '#login') ||
                (global && global.window && global.window.location && global.window.location.hash === '#registration')
                  ? false
                  : show
              }
            />
          </>
        )}
        withLoading={() => (
          <Header
            // promoOpen={promoOpen || pricingPromo}
            // holidaysSale={holidaysSale}
            // setPromoOpen={setPromoOpen}
            menuItems={HeaderMenuItems(false)}
            loading
            pricingPage={pricingPage}
            pricingPageLtd={pricingPageLtd}
            fusebasePromo={fusebasePromo}
            shorthLtd={shorthLtd}
            noBanner={noBanner}
          />
        )}
        withUser={user => (
          <Header
            // promoOpen={promoOpen || pricingPromo}
            // holidaysSale={holidaysSale}
            // setPromoOpen={setPromoOpen}
            user={user}
            menuItems={HeaderMenuItems(true, user.firstName, +user.id)}
            pricingPage={pricingPage}
            pricingPageLtd={pricingPageLtd}
            fusebasePromo={fusebasePromo}
            shorthLtd={shorthLtd}
            noBanner={noBanner}
          />
        )}
      />
      <div style={{marginTop: promoOpen ? '' : ''}} className="content-wrapper">
        {showLogin && (
          <CustomModal ShowPopup={showLogin} setShowPopup={closeModal}>
            <Login
              setShowLogin={setShowLogin}
              setShowRegistration={setShowRegistration}
              setShowForgotPassword={setShowForgotPassword}
              setHandleCloseLoginModal={setHandleCloseLoginModal}
            />
          </CustomModal>
        )}
        {showDemo && (
          <CustomModal
            ShowPopup={showDemo}
            setShowPopup={closeDemoModal}
            className="demo-request"
            fitContent={demoSuccess}
          >
            <RequestDemo setDemoSuccess={setDemoSuccess} setTimeoutId={setTimeoutId} />
          </CustomModal>
        )}
        {showRegistration && (
          <CustomModal ShowPopup={showRegistration} setShowPopup={closeModal}>
            <Registration
              setShowLogin={setShowLogin}
              setShowRegistration={setShowRegistration}
              promoCode={promoCode.slice(0, promoCode.length - 1)}
              setHandleCloseRegModal={setHandleCloseRegModal}
            />
          </CustomModal>
        )}
        {showForgotPassword && (
          <CustomModal
            ShowPopup={showForgotPassword}
            setShowPopup={setShowForgotPassword}
            showBackBtn
            onBackBtnClick={() => {
              setShowLogin(true);
              setShowForgotPassword(false);
            }}
          >
            <ForgotPassword setShowForgotPassword={setShowForgotPassword} />
          </CustomModal>
        )}
        {children}
      </div>
      <Footer pricingPage={pricingPage} />
      <CookieConsentPopup />
      {/* <StickyMessage /> */}
    </>
  );
};

export default Layout;
